
  import * as Sentry from "@sentry/react"

  Sentry.init({
    dsn: "https://bde7bf05d3c3a7e9ee30eb493145b521@o4507378228068352.ingest.de.sentry.io/4507531467554896",
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true
      }),
    ],
    environment: stage,
    tracesSampleRate: 0,
    tracePropagationTargets: [/^https:\/\/staging.zero.giki.earth/, /^https:\/\/zero.giki.earth/],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    ignoreErrors: ['Non-Error promise rejection captured']
  })

